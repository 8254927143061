import consumer from "./consumer"

consumer.subscriptions.create("DataSourcesChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'data_source_crawled') {
      $(`#data_source_${data.content.id}`).removeClass('running').addClass('completed');
      $(`#data_source_${data.content.id}`).replaceWith(data.content.html);
      toastr.success('Data Source crawled successfully');
    } else if (data.content.type == 'data_source_error') {
      toastr.error(`Data Source could not be crawled. ${data.content.error}`);
      $(`#data_source_${data.content.id}`).replaceWith(data.content.html);
    }
  }
});
